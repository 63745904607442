import React from 'react';
import Roblox from 'Roblox';
import { render } from 'react-dom';
import { ready } from 'core-utilities';
import { rootElementId } from './app.config';
import '../../../css/idVerification/emailUpsell.scss';
import '../../../css/idVerification/viewTemplate.scss';
import App from './App';
import {
  getVeriffVerificationStatus,
  getPersonaVerificationStatus,
  getVerifiedAge,
  startVerificationFlow,
  sendIdVerificationEvent,
  showBirthdayChangeWarning
} from './services/ageVerificationServices';
import { showVoiceOptInOverlay } from './services/voiceChatService';
import getAccountInformationMetadata from '../../../ts/react/idVerification/services/accountInformationService';
import Vendor from '../../../ts/react/idVerification/enums/Vendor';

// Expose service to internal apps
Roblox.IdentityVerificationService = {
  getVeriffVerificationStatus,
  getPersonaVerificationStatus,
  getVerifiedAge,
  startVerificationFlow,
  sendIdVerificationEvent,
  showBirthdayChangeWarning,
  showVoiceOptInOverlay
};

async function renderApp() {
  const entryPoint = document.getElementById(rootElementId);
  if (entryPoint) {
    const entryAttr = entryPoint.getAttribute('entry');
    const accountInformationMetadata = await getAccountInformationMetadata();
    const vendor = accountInformationMetadata.shouldUsePersonaForIdVerification
      ? Vendor.Persona
      : Vendor.Veriff;

    if (entryAttr !== null) {
      render(<App entry={entryAttr} vendor={vendor} />, entryPoint);
    } else {
      render(<App vendor={vendor} />, entryPoint);
    }
  } else {
    // Recursively call renderApp if target div not found
    // Callback will be triggered before every repaint
    window.requestAnimationFrame(renderApp);
  }
}
ready(async () => {
  if (rootElementId) {
    await renderApp();
  }
});
