import { EnvironmentUrls } from 'Roblox';
import { httpService } from 'core-utilities';
import { TAccountInformationMetadataResponse } from '../types/accountInformationTypes';

const getAccountInformationMetadata = async (): Promise<TAccountInformationMetadataResponse> => {
  const urlConfig = {
    url: `${EnvironmentUrls.accountInformationApi}/v1/metadata`,
    withCredentials: true
  };
  return httpService.get<TAccountInformationMetadataResponse>(urlConfig).then(
    ({ data }) => {
      return data;
    },
    () => {
      const data: TAccountInformationMetadataResponse = {
        isAllowedNotificationsEndpointDisabled: false,
        isAccountSettingsPolicyEnabled: false,
        isPhoneNumberEnabled: false,
        maxUserDescriptionLength: 0,
        isUserDescriptionEnabled: false,
        isUserBlockEndpointsUpdated: false,
        isPasswordRequiredForAgingDown: false,
        shouldUsePersonaForIdVerification: false
      };
      return data;
    }
  );
};

export default getAccountInformationMetadata;
